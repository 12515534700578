import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectupBanner from '@components/projectupBanner';
import ContactSection from '@components/contactSection';
import ProductFeatures from '@components/productFeatures';

import "@styles/styles.scss";

export const ProjectUpPage = ({ data }) => {
  const ctf = data.contentfulProjectUpPage;
  const { headline, subtitle } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const featuresTitle = ctf.valuePropositionTitle;
  const featuresSubtitle = ctf.valuePropositionSubtitle.valuePropositionSubtitle;
  const features = ctf.features;

  return (
    <Layout>
      <SEO title="Project Up" />
      <ProjectupBanner
        headline={headline}
        subtitle={subtitle.subtitle}
        assets={assets}
      />
      <ProductFeatures
        title={featuresTitle}
        subtitle={featuresSubtitle}
        features={features}
        assets={assets}
      />
      <ContactSection
        defaultProduct={'Project Up'}
      />
    </Layout>
  );
};

export default ProjectUpPage;

export const pageQuery = graphql`
  query ProjectUpQuery {
    contentfulProjectUpPage {
      headline
      subtitle {
        subtitle
      }
      features {
        featureContent
        featureTitle
        imageTitle
      }
      valuePropositionTitle
      valuePropositionSubtitle {
        valuePropositionSubtitle
      }
    }
    allContentfulAsset {
      edges {
        node {
          file {
            contentType
            url
            fileName
          }
          title
        }
      }
    }
  }
`;
